import React, { useState, useEffect } from 'react';
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
} from 'date-fns';

import { zonedTimeToUtc } from 'date-fns-tz';
import './GameCountdown.css';

const GameCountdown = ({ date }) => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const calculateTimeLeft = () => {
    const currentTime = zonedTimeToUtc(new Date(), userTimeZone);
    const zonedDate = zonedTimeToUtc(date, userTimeZone);
    const difference = differenceInSeconds(zonedDate, currentTime);
    let timeLeft = {};
    if (difference > 0) {
      timeLeft = {
        days: differenceInDays(zonedDate, currentTime),
        hours: differenceInHours(zonedDate, currentTime) % 24,
        minutes: differenceInMinutes(zonedDate, currentTime) % 60,
        seconds: difference % 60,
      };
    }
    return timeLeft;
  };
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const addLeadingZero = (value) => {
    return value < 10 ? `0${value}` : value;
  };

  const { days, hours, minutes, seconds } = timeLeft;

  return (
    <div className="gameCountdown-container">
      <div className="gameCountdown-item">
        <span className="gameCountdown-value">{days}</span>
        <span className="gameCountdown-label">
          {days === 1 ? "Day" : "Days"}
        </span>
      </div>
      <div className="gameCountdown-item">
        <span className="gameCountdown-value">{addLeadingZero(hours)}</span>
        <span className="gameCountdown-label">Hours</span>
      </div>
      <div className="gameCountdown-item">
        <span className="gameCountdown-value">{addLeadingZero(minutes)}</span>
        <span className="gameCountdown-label">Minutes</span>
      </div>
      <div className="gameCountdown-item">
        <span className="gameCountdown-value">{addLeadingZero(seconds)}</span>
        <span className="gameCountdown-label">Seconds</span>
      </div>
    </div>
  );
};

export default GameCountdown;
