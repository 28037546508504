import React from "react";
import ReactDOM from "react-dom";
import preloaded from "../../src/preloaded.js";
import GameCountdown from "../../src/games/GameCountdown.js";

const target = document.getElementById("gameCountdown");
const { next_game_date } = preloaded;
if (target) {
  ReactDOM.render(<GameCountdown date={next_game_date} />, target);
}
